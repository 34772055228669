import { MeiliSearch } from "meilisearch";
import type { ImplyIndex, KnowIndex } from "~/types/models";

export const meiliClient = new MeiliSearch({
  host: import.meta.env.VITE_MEILI_URL || "http://127.0.0.1:7700",
  apiKey: import.meta.env.VITE_MEILI_KEY,
});

export const knowsIndex = meiliClient?.index<KnowIndex>("knows");
export const impliesIndex = meiliClient?.index<ImplyIndex>("implies");
